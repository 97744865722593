import React, { useRef } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { initUploadToS3, cdnUrl } from '../../helpers/file';

const BASE_BUTTONS = [
  ['undo', 'redo'],
  ['formatBlock'],
  ['bold', 'underline', 'italic', 'list'],
  ['link'],
];

const OTHER_BUTTONS = [['fullScreen'], ['codeView']];

const HTMLEditor = ({
  sunEditorRef,
  imageRemotePath,
  initContents,
  onChange,
  buttons = [],
  ...props
}) => {
  const editor = useRef();

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
    sunEditorRef && sunEditorRef(sunEditor);
  };

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    const file = files[0];
    try {
      const uploadS3 = initUploadToS3(
        file,
        file.type,
        `${imageRemotePath}/${file.name}`,
      );
      uploadS3.done().then((response) => {
        return uploadHandler({
          result: [
            {
              url: cdnUrl(response.Location),
              name: file.name,
            },
          ],
        });
      });
    } catch (error) {
      return uploadHandler(error.message);
    }
  };

  return (
    <SunEditor
      getSunEditorInstance={getSunEditorInstance}
      onImageUploadBefore={handleImageUploadBefore}
      setContents={initContents}
      onChange={onChange}
      setDefaultStyle="font-family: 'Poppins', sans-serif"
      setOptions={{
        formats: ['p', 'h2', 'h3'],
        buttonList: [...BASE_BUTTONS, ...buttons, ...OTHER_BUTTONS],
      }}
      {...props}
    />
  );
};
export default HTMLEditor;
