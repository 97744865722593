import React, { useState, useEffect } from 'react';

import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import { Dots } from 'react-activity';

import * as usersActions from '../../store/users/actions';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import FormUser from '../../components/Users/FormUser';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import createTableColumns from '../../components/Users/tableColumns';

const List = (props) => {
  const loading = useSelector((state) => state.User.loading);

  const users = useSelector((state) => state.User.users);
  const user = useSelector((state) => state.Auth.admin);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.syncUsers());
  }, []);

  const openEditModal = (user = null) => {
    setSelectedUser(user);
    setEditModal(true);
  };

  const openViewModal = (user) => {
    setSelectedUser(user);
    setViewModal(true);
  };

  const columns = createTableColumns(openViewModal, openEditModal, user);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Users" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <DataTableNext
                    loading={loading}
                    rows={users}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="User"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedUser}
              collection="users"
              icon="bx bx bx-user-circle"
            />
          </Modal>
          <Modal
            size="xl"
            title="User"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormUser
              closeModal={() => setEditModal(false)}
              create={!selectedUser}
              loading={loading}
              user={selectedUser}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
