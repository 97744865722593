import React, { useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import { Form, Input } from '@availity/form';
import Select from '@availity/select';
import formValidation from './formValidation';

import * as usersActions from '../../store/users/actions';
import { userEmailInUse } from '../../selectors/user';
import { useDispatch, useSelector } from 'react-redux';

import assignAllowedRoles from '../../config/assignAllowedRoles';
import roles from '../../config/roles';
import allowedLanguages from '../../config/allowedLanguages';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { deleteField } from 'firebase/firestore';

const FormUser = (props) => {
  const editingUser = props.user || {};

  const user = useSelector((state) => state.Auth.admin);
  const allowedRoles = assignAllowedRoles[user.role];
  const emailsInUse = useSelector(userEmailInUse(editingUser.id));

  const [active, setActive] = useState(editingUser.active || false);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(
      props.create
        ? usersActions.createUser({ ...values, active })
        : usersActions.updateUser({
            id: editingUser.id,
            ...values,
            ...(values.role !== roles.TRANSLATOR && {
              languages: deleteField(),
            }),
            active,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={editingUser}
          validationSchema={formValidation(emailsInUse)}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="firstName">First Name</Label>
                    <Input type="text" name="firstName" id="firstName" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="lastName">Last Name</Label>
                    <Input type="text" name="lastName" id="lastName" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="displayName">Display Name</Label>
                    <Input type="text" name="displayName" id="displayName" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        defaultChecked={active}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="active"
                        onClick={(e) => {
                          setActive(!active);
                        }}>
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      disabled={user.role !== roles.SUPER_ADMIN}
                    />
                  </FormGroup>
                </Col>
                {(editingUser.role !== user.role ||
                  user.role === roles.SUPER_ADMIN) && (
                  <Col md="2">
                    <FormGroup>
                      <Label htmlFor="role">Role</Label>
                      <Select
                        name="role"
                        id="role"
                        options={allowedRoles.map((role) => ({
                          label: role,
                          value: role,
                        }))}
                      />
                    </FormGroup>
                  </Col>
                )}
                {values.role === roles.TRANSLATOR && (
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="languages">Language</Label>
                      <Select
                        name="languages"
                        id="languages"
                        isMulti
                        options={allowedLanguages}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormUser;
