import React from 'react';
import { Redirect } from 'react-router-dom';
import roles from '../config/roles';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Register from '../pages/Authentication/Register';
import Logout from '../pages/Authentication/Logout';
import ForgetPassword from '../pages/Authentication/ForgetPassword';

// All protected pages
import Dashboard from '../pages/Dashboard/index';
import ArticlesList from '../pages/Articles/List';
import VideosList from '../pages/Videos/List';
import SymptomsList from '../pages/Symptoms/List';
import ContestsList from '../pages/Contests/List';
import WebContentsList from '../pages/WebContents/List';
import PushNotificationsList from '../pages/PushNotifications/List';
import MembersList from '../pages/Members/List';
import CountriesList from '../pages/Countries/List';
import UsersList from '../pages/Users/List';

const userRoutes = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'bx bx-home-alt',
    component: Dashboard,
  },
  {
    title: 'Articles',
    path: '/articles/list',
    component: ArticlesList,
    icon: 'dripicons-article',
    permission: [
      roles.SUPER_ADMIN,
      roles.ADMIN,
      roles.MANAGER,
      roles.EDITOR,
      roles.TRANSLATOR,
    ],
  },
  {
    title: 'Video',
    path: '/videos/list',
    component: VideosList,
    icon: 'bx bx-video',
    permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER, roles.EDITOR],
  },
  {
    title: 'Symptom',
    path: '/symptoms/list',
    component: SymptomsList,
    icon: 'mdi mdi-hospital-box-outline',
    permission: [
      roles.SUPER_ADMIN,
      roles.ADMIN,
      roles.MANAGER,
      roles.EDITOR,
      roles.TRANSLATOR,
    ],
  },
  {
    title: 'Contests',
    path: '/contests/list',
    component: ContestsList,
    icon: 'dripicons-broadcast',
    permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER, roles.EDITOR],
  },
  {
    title: 'Web Contents',
    path: '/webContents/list',
    component: WebContentsList,
    icon: 'bx bx-receipt',
    permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER, roles.EDITOR],
  },
  {
    title: 'Push Notifications',
    path: '/pushNotifications/list',
    component: PushNotificationsList,
    icon: 'bx bx-bell',
    permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER, roles.EDITOR],
  },
  {
    title: 'Members',
    path: '/members/list',
    component: MembersList,
    icon: 'bx bx-user-pin',
    permission: [roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER, roles.EDITOR],
  },
  {
    title: 'Countries',
    path: '/countries/list',
    component: CountriesList,
    icon: 'bx bx-world',
    permission: [roles.SUPER_ADMIN, roles.MANAGER, roles.ADMIN],
  },
  {
    title: 'Users',
    path: '/users/list',
    component: UsersList,
    icon: 'bx bx-user-circle',
    permission: [roles.SUPER_ADMIN, roles.ADMIN],
  },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/forgot-password', component: ForgetPassword },
];

export { userRoutes, authRoutes };
