import roles from './roles';

export default {
  SUPER_ADMIN: [
    roles.SUPER_ADMIN,
    roles.ADMIN,
    roles.MANAGER,
    roles.EDITOR,
    roles.TRANSLATOR,
  ],
  ADMIN: [roles.ADMIN, roles.MANAGER, roles.EDITOR, roles.TRANSLATOR],
  MANAGER: [roles.MANAGER, roles.EDITOR, roles.TRANSLATOR],
  EDITOR: [roles.EDITOR, roles.TRANSLATOR],
  TRANSLATOR: [roles.TRANSLATOR],
};
