import React, { useState, useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  Alert,
  Spinner,
} from 'reactstrap';
import { Form, Input } from '@availity/form';
import FormErrorMessage from '../../Common/FormErrorMessage';
import HTMLEditor from '../../Common/HTMLEditor';
import CenterBox from '../../Common/CenterBox';
import { Dots } from 'react-activity';
import formValidation from './formValidation';

import * as symptomContentsActions from '../../../store/symptoms/contents/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectSymptomContent } from '../../../selectors/symptom';
import { translatorLanguages } from '../../../selectors/auth';
import { SubmitButton } from '../../Common/Modal/modalActionButtons';
import { getFunctions, httpsCallable } from 'firebase/functions';
import colors from '../../CommonStyle/colors';

const STORAGE_BASE_PATH = 'symptoms';

const functions = getFunctions();
const copyTranslateSymptom = httpsCallable(functions, 'copyTranslateSymptom');

let sunEditor = null;
const sunEditorRef = (editor) => {
  sunEditor = editor;
};

const FormSymptomContentContent = ({ symptom, lang, closeModal }) => {
  const symptomContentId = `${symptom.id}-${lang}`;
  const loading = useSelector((state) => state.SymptomContent.loading);

  const symptomContent = useSelector(selectSymptomContent(symptomContentId));
  const create = !symptomContent;

  const enabledLanguages = useSelector(translatorLanguages);
  const canEdit = enabledLanguages.includes(lang);

  const [loadingTranslation, setLoadingTranslation] = useState(false);
  const [errorTranslation, setErrorTranslation] = useState('');

  const [content, setContent] = useState(symptomContent?.content || '');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(symptomContentsActions.fetchSymptomContent(symptomContentId));
  }, []);

  const handleSubmit = (values) => {
    dispatch(
      create
        ? symptomContentsActions.createSymptomContent(
            {
              id: symptomContentId,
              ...values,
              symptomId: symptom.id,
              lang,
              content,
              published: values.published || false,
            },
            symptom,
          )
        : symptomContentsActions.updateSymptomContent({
            id: symptomContentId,
            ...values,
            content,
            published: values.published || false,
          }),
    );
    closeModal();
  };

  const handleContentChange = (contents) => {
    setContent(contents);
  };

  const handleTranslate = useCallback(
    (setFieldValue) => {
      setErrorTranslation(null);
      setLoadingTranslation(true);

      copyTranslateSymptom({
        symptomContentId: `${symptom.id}-IT`,
        targetLang: lang,
      })
        .then((result) => {
          const data = result.data;
          const { error, title, content } = data;
          if (error) throw new Error(error.message);

          title && setFieldValue('title', title);
          content && sunEditor && sunEditor.setContents(content);
        })
        .catch((error) => {
          setErrorTranslation(error.message);
          console.error(error);
        })
        .finally(() => {
          setLoadingTranslation(false);
        });
    },
    [symptom, lang],
  );

  return (
    <Card>
      <CardBody>
        {loading ? (
          <CenterBox height={500}>
            <Dots color={colors.info} size={30} />
          </CenterBox>
        ) : (
          <Form
            initialValues={symptomContent || {}}
            validationSchema={formValidation}
            onSubmit={handleSubmit}>
            {({ errors, isValid, values, setFieldValue }) => (
              <>
                <Collapse
                  isOpen={!!(Object.keys(errors).length || errorTranslation)}>
                  <>
                    {!!Object.keys(errors).length && (
                      <Alert color="danger">{Object.values(errors)[0]}</Alert>
                    )}
                    {errorTranslation && (
                      <Alert color="danger">{errorTranslation}</Alert>
                    )}
                  </>
                </Collapse>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="title">Title</Label>
                      <Input type="text" name="title" id="title" />
                      <FormErrorMessage name="title" />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="published"
                          name="published"
                          checked={!!values.published}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="published">
                          Published?
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  {symptomContent?.wpPostId && (
                    <Col md="2">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-secondary"
                        onClick={() =>
                          window.open(
                            `https://www.mammacheapp.com/wp-admin/post.php?post=${symptomContent?.wpPostId}&action=edit`,
                            '_blank',
                          )
                        }
                        disabled={loading}>
                        <i className="bx bxl-wordpress mr-1"></i>
                        Open old CMS
                      </button>
                    </Col>
                  )}
                  {symptomContent?.lang !== 'IT' && (
                    <Col md="2" className="d-inline-block my-auto">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success"
                        onClick={() => handleTranslate(setFieldValue)}
                        disabled={loading || loadingTranslation}>
                        <i className="bx bx-copy-alt mr-1"></i>
                        Copy from IT
                      </button>
                      <Spinner
                        size="sm"
                        color="primary"
                        hidden={!loadingTranslation}
                        className="ml-2"
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="content">Content</Label>
                      <HTMLEditor
                        sunEditorRef={sunEditorRef}
                        lang="en"
                        initContents={symptomContent?.content || ''}
                        buttons={[['image'], ['table']]}
                        onChange={handleContentChange}
                        imageRemotePath={`${STORAGE_BASE_PATH}/${symptom.id}/contentImages`}
                        height="350px"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="modal-footer">
                  <SubmitButton
                    disabled={!isValid || loading || !canEdit}
                    create={create}
                  />
                </div>
              </>
            )}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};

export default FormSymptomContentContent;
