export const STATUS = {
  DRAFT: 'draft',
  READY: 'ready',
  SCHEDULED: 'scheduled',
  PROCESSING: 'processing',
  DELIVERING: 'delivering',
  SENT: 'sent',
  ERROR: 'error',
};

export const LINK_PRESETS = [
  { label: 'HP Contest', link: 'contests' },
  { label: 'HP Symptom', link: 'symptoms-checker' },
  { label: 'Calculators', link: 'calculators' },
  {
    label: 'HP Article Pregnancy',
    link: 'articles/true',
  },
  {
    label: 'HP Article Post Pregnancy',
    link: 'articles/false',
  },
  { label: 'HP Article Category', link: 'articlesWithCategory/psiche' },
  { label: 'HP Video Pregnancy', link: 'videos/true' },
  { label: 'HP Video Post Pregnancy', link: 'videos/false' },
];

export const generateDeeplink = (contentId, contentType) => {
  const path =
    contentType === 'article'
      ? 'articlesWithId'
      : contentType === 'video'
      ? 'videosWithId'
      : 'symptomsWithId';
  return `${path}/${encodeURIComponent(contentId)}`;
};

// const links = {
//   REBRANDLY: 'web-view/https%3A%2F%2Frebrand.ly%2FHenkel_NP_MCA/normalMode',
//   CONTESTS: 'contests',
//   ARTICLE: `articlesWithId/${encodeURIComponent('cYnkKtKKlsQbrpLZ2tim-IT')}`,
//   ARTICLE_CATEGORY: `articlesWithCategory/${encodeURIComponent('psiche')}`,
//   SYMPTOMS: 'symptoms-checker',
//   CALCULATORS: 'calculators',
//   VIDEO_PRE: 'videos/true',
//   VIDEO_POST: 'videos/false',
// };
